/* src/components/ChatBox.css */


.attach-button {
    background-color: #424242;
    border: none;
    color: #fff;
    font-size: 10px;
    margin-right: 10px;
}

.chatbox-input {
    background-color: transparent;
    border: none;
    color: #141414;
    flex-grow: 1;
    outline: none;
    height: 2.5rem;

}

.chatbox-input::placeholder {
    color: #353535;
}

.chatbox-file-input {
    display: none;
}


.response-container {
    margin: 20px 0;
    padding: 2rem;
    background-color: #f5fff72a;
    border-radius: 1.5rem;
    box-shadow: 0px 0px 15px 4px rgba(18, 56, 3, 0.25);

}

.response-container h3 {
    margin-top: 0;
}

.response-container pre {
    white-space: pre-wrap; /* Ensures that long lines wrap inside the container */
    word-wrap: break-word; /* Breaks words if they are too long */
}

.response-container details {
    width: 100%;
    overflow: auto;
}

/* Responsive styles for the <ul> element */
.response-container ul {
    padding: 0;
    list-style-type: none;
}

.response-container li {
    padding: 0.5rem 0;
}

.response-container a {
    word-break: break-word; /* Ensure long URLs break correctly */
}