.chatbox {
    height: 3rem;
    width: 40rem;
    border-radius: 20px;
    margin-top: 1.4rem;
    display: flex;
    position: relative;
    align-items: center;
    background-color: #ffffff00;
    box-shadow: 0px 0px 25px 10px rgba(18, 56, 3, 0.25);
    color: #ffffff;

}

.chatbox:hover {
    border: 1px solid #043310 !important;
}

.chatbox input {
    background-color: transparent !important;
    border: none;
    width: 80%;
    margin-left: 10%;
    height: 100%;
    border-radius: 20px;
    color: #043310;

}
.chatbox input:focus {
    background-color: transparent !important;
    outline: none; 
    box-shadow: none;
    color: #043310;

}

.attach-button{
    position: absolute;
    left: 15px;
    background-color: transparent !important;
    z-index: 10;
    color: #043310 !important;
    font-size: 1rem !important;
}

.submit {
    position: absolute;
    right: 15px;
    z-index: 10;
}

@media screen and (max-width: 800px) {
    .chatbox {
        width: 20rem; /* Adjusted width for smaller screens */
    }

    
.attach-button{
    position: absolute;
    left: 10px;
    background-color: transparent !important;
    z-index: 10;
    color: #043310 !important;
    font-size: 1rem;
}

.submit {
    position: absolute;
    right: 10px;
    z-index: 10;
}

.chatbox input {
    width: 75%;
}
}