/* Overall container for the chat app */
.chatApp {
    display: flex;
    height: 100vh;
    background-color: #f4f4f9;
}

/* Sidebar container for chat history */
.chatSidebar {
    width: 25%;
    background-color: #ffffff;
    padding: 20px;
    border-right: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    overflow-y: auto; /* Enables vertical scrolling */
    transition: all 0.3s ease;
}

.chatSidebar:hover {
    overflow-y: scroll; /* Ensure scrolling appears when hovering */
}

/* Chat History Container */
.chatHistoryContainer {
    flex-grow: 1; /* Takes remaining space next to the sidebar */
    height: calc(90vh); /* Adjust to take full height minus the header and input space */
    overflow-y: auto; /* Enables vertical scrolling */
    padding-right: 10px; /* Prevent scrollbar from covering content */
    margin-bottom: 120px; /* Adds space at the bottom */
    display: flex;
    flex-direction: column-reverse; /* Reverse the order for more natural scroll experience */
}

/* Add hover effect to trigger scrolling */
.chatHistoryContainer:hover {
    overflow-y: scroll; /* Ensure scrolling appears when hovering */
}

/* Optional: Style the scrollbar to make it more user-friendly */
.chatSidebar::-webkit-scrollbar, 
.chatHistoryContainer::-webkit-scrollbar {
    width: 8px; /* Adjust scrollbar width */
}

/* Chat history item styling */
.chatHistoryItem {
    background-color: #f9f9f9;
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.chatHistoryItem:hover {
    background-color: #e1e1e1;
}

.selectedID {
    background-color: #424242;
    color: white;
}

/* Main chat container */
.chatContainer {
    margin: auto;
    width: 65vw;
    height: 95vh;
    padding: 20px;
    background-color: #ffffff;
    border-left: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    right: 0;
    overflow: hidden; /* Prevent scrolling */
    position: relative;
}

/* Chat history container for displaying questions and responses */
.messageContainer {
    display: flex;
    flex-direction: column;
}

/* Message bubble styling */
.messageBubble {
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
}

.userBubble {
    align-self: flex-end;
    text-align: right;
    margin: 5px 0;
    padding: 10px;
}

.assistantBubble {
    align-self: flex-start;
    text-align: left;
    margin: 5px 0;
    padding: 10px;
}

/* References section */
.details summary {
    font-weight: bold;
    cursor: pointer;
}

.details ul {
    margin-top: 10px;
    list-style-type: none;
    padding: 0;
}

.details li {
    background-color: #e9ecef;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 10px;
}

/* Loading spinner container */
.loadingIndicator {
    text-align: center;
    padding: 20px;
}

/* Chat input form styling */
.chatInputForm {
    display: flex;
    align-items: center;
    margin-top: auto;
    bottom: 30px;
    position: fixed;
    width: 60vw;
    background-color: #f9f9f9;
    border-top: 1px solid #ddd;
    padding: 10px;
    justify-content: space-between;
}

.chatInput {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    margin-right: 10px;
}

.submitBtn {
    width: 40px;
    height: 40px;
    cursor: pointer;
    object-fit: contain;
}

.submitBtn:hover {
    opacity: 0.8;
}

.assistant {
    margin-left: 0px;
}

.noitem {
    display: none;
}

.shareBtn {
    max-width: 100%;
    width: 120px;
    height: 30px;
    padding: 10px;
    font-size: 16px;
    border-radius: 20px;
    align-self: flex-end;
    position: absolute; /* Fixes the Share button */
    top: 20px;
    right: 20px;
    z-index: 10; /* Make sure it's on top */
}

.newChatBtn{
    max-width: 100%;
    margin: 10px auto;
    width: 300px !important;
    height: 40px !important;
    border-radius: 20px !important;
    background-color: #147885 !important;
    color: white;


}