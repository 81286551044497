/* Custom CSS for the dashboard layout */

.dashboard-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  

}

.navbar {
  flex-shrink: 0;
  z-index: 2000;
  box-shadow: 0px 1px 2px rgba(69, 28, 18, 0.02), 0px 5px 9px rgba(69, 28, 18, 0.03), 0px 12px 20px rgba(69, 28, 18, 0.05), 0px 20px 36px rgba(69, 28, 18, 0.06), 0px 32px 56px rgba(69, 28, 18, 0.08);
}

.sidebar {
  flex-shrink: 0;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  overflow-y: auto;
  width: 250px;
  color: #ffffff;
  background-color: #147885 !important;
}

.sidebar .nav-link.active {
  font-weight: bold;
  color: #ffffff;
}

.sidebar .navbar-brand{
  font-weight: bold;
  color: #ffffff;
  padding: 2rem;
  font-size: 2rem;
}

.sidebar .nav-link {
  font-weight: bold;
  color: #ffffff;
  padding: 1rem;
  
}

.sidebar .nav-link:hover {
  font-weight: bold;
  background-color: #ffffff;
  padding: 1rem;
  color: #147885 !important;

}

.main-content {
  margin-left: 250px;
  padding: 1rem;
  flex-grow: 1;
}

.content-area {
  background-color: #f8f9fa;
  border-radius: 5px;
  padding: 2rem;
  min-height: 80vh;
}



body {
  padding-bottom: 30px;
  position: relative;
  min-height: 100%;
  background-color: #fff ;
  color: #043033;
}

a {
  transition: background 0.2s, color 0.2s;
}
a:hover,
a:focus {
  text-decoration: none;
}

#wrapper {
  padding-left: 0;
  transition: all 0.5s ease;
  position: relative;
}

#sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  left: 250px;
  width: 0;
  height: 100%;
  margin-left: -250px;
  overflow-y: auto;
  overflow-x: hidden;
  background: #f5f5f5;
  transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
  width: 250px;
}


#wrapper.toggled .sidebar-brand {
  display: none;
}


.sidebar-brand {
  position: absolute;
  top: 0;
  width: 250px;
  text-align: center;
  padding: 20px 0;
}
.sidebar-brand h2 {
  margin: 0;
  font-weight: 600;
  font-size: 24px;
  color: #043033;
}

.sidebar-nav {
  position: absolute;
  bottom: 2rem;
  width: 250px;
  margin: 0;
  padding: 0;
  list-style: none;
}
.sidebar-nav > li {
  text-indent: 10px;
  line-height: 42px;
}
.sidebar-nav > li a {
  display: block;
  text-decoration: none;
  color: #043033;
  font-weight: 600;
  font-size: 18px;
}
.sidebar-nav > li > a:hover,
.sidebar-nav > li.active > a {
  text-decoration: none;
  color: #043033;
  background: #f5f5f5;
}
.sidebar-nav > li > a i.fa {
  font-size: 24px;
  width: 60px;
}

#navbar-wrapper {
    width: 100%;
    position: absolute;
    z-index: 2;
}
#wrapper.toggled #navbar-wrapper {
    position: absolute;
    margin-right: -250px;
}
#navbar-wrapper .navbar {
  border-width: 0 0 0 0;
  background: #fff;
  font-size: 24px;
  margin-bottom: 0;
  border-radius: 0;
}
#navbar-wrapper .navbar a {
  color: #757575;
}


#content-wrapper {
  width: 100%;
  position: absolute;
  padding: 15px;
  top: 100px;
}
#wrapper.toggled #content-wrapper {
  position: absolute;
  margin-right: -250px;
}

@media (min-width: 992px) {
  #wrapper {
    padding-left: 250px;
  }
  
  #wrapper.toggled {
    padding-left: 60px;
  }

  #sidebar-wrapper {
    width: 250px;
  }
  
  #wrapper.toggled #sidebar-wrapper {
    width: 60px;
  }
  
  #wrapper.toggled #navbar-wrapper {
    position: absolute;
    margin-right: -190px;
}
  
  #wrapper.toggled #content-wrapper {
    position: absolute;
    margin-right: -190px;
  }

  #navbar-wrapper {
    position: relative;
  }

  #wrapper.toggled {
    padding-left: 60px;
  }

  #content-wrapper {
    position: relative;
    top: 0;
  }

  #wrapper.toggled #navbar-wrapper,
  #wrapper.toggled #content-wrapper {
    position: relative;
    margin-right: 60px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #wrapper {
    padding-left: 60px;
  }

  #sidebar-wrapper {
    width: 60px;
  }
  
#wrapper.toggled #navbar-wrapper {
    position: absolute;
    margin-right: -250px;
}
  
  #wrapper.toggled #content-wrapper {
    position: absolute;
    margin-right: -250px;
  }

  #navbar-wrapper {
    position: relative;
  }

  #wrapper.toggled {
    padding-left: 250px;
  }

  #content-wrapper {
    position: relative;
    top: 0;
  }

  #wrapper.toggled #navbar-wrapper,
  #wrapper.toggled #content-wrapper {
    position: relative;
    margin-right: 250px;
  }
}

@media (max-width: 767px) {
  #wrapper {
    padding-left: 0;
  }

  #sidebar-wrapper {
    width: 0;
  }

  #wrapper.toggled #sidebar-wrapper {
    width: 250px;
  }
  #wrapper.toggled #navbar-wrapper {
    position: absolute;
    margin-right: -250px;
  }

  #wrapper.toggled #content-wrapper {
    position: absolute;
    margin-right: -250px;
  }

  #navbar-wrapper {
    position: relative;
  }

  #wrapper.toggled {
    padding-left: 250px;
  }

  #content-wrapper {
    position: relative;
    top: 0;
  }

  #wrapper.toggled #navbar-wrapper,
  #wrapper.toggled #content-wrapper {
    position: relative;
    margin-right: 250px;
  }
}

.halfsize{
  min-height: 35vh !important;
  background-color: transparent;
  display: flex;
  flex-direction: column; /* Add this line */
  justify-content: flex-end; /* Adjust to flex-end to push content to the bottom */
  align-items: center;
}

.halfsizesecond{
  min-height: 45vh !important;
  background-color: transparent;
  display: flex;
  justify-content: center;
}

.leftmargin {
  margin-left: 10px;
  margin-right: 30px;
  
}


.primary{
  background-color: #147885 !important;
  color: #fff;
  background-image: #043310;
  border-radius: 6px;
  justify-content: center !important;
  align-items: center;
  height: auto;
  padding: 10px 10px !important;
  font-weight: 500;
  text-decoration: none;
  display: flex;
  width: 230px;
  }

  @media screen and (max-width: 800px) {

    .halfsize{
      height: 35vh !important;
      min-height: 35vh !important;
 
    }
    
    .halfsizesecond{
      height: 35vh !important;
   
    }
    }