.App {
  text-align: left;
  font-family: "Inter", sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

main{

  border-radius: 1rem;
  background-color: rgba(255, 250, 250, 0);
}
/* src/App.css */
.container {
  margin-top: 50px;
}
.menucontainer{
  margin-top: 10px !important;
}

h1 {
  margin-bottom: 30px;
}

.form-control {
  margin-bottom: 10px;
}

.alert {
  margin-top: 10px;
}


.specialbox{
  margin: auto;
  padding: 2rem;
  box-shadow: 0px 1px 2px rgba(69, 28, 18, 0.02), 0px 5px 9px rgba(69, 28, 18, 0.03), 0px 12px 20px rgba(69, 28, 18, 0.05), 0px 20px 36px rgba(69, 28, 18, 0.06), 0px 32px 56px rgba(69, 28, 18, 0.08);
}

.btn-primary{
  color: white;
  background-color: #147885 !important;
  border: none;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-size: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem;

}







.navbar {
  flex-shrink: 0;
  z-index: 100;
  box-shadow: none!important;
  font-size: 1rem;
}

a.primarybutton{
background-color: #147885 !important;
color: #fff;
background-image: linear-gradient(rgba(0, 0, 0, 0) 67%, rgba(0, 86, 92, .52));
border-radius: 6px;
justify-content: center !important;
align-items: center;
height: auto;
padding: 16px 24px !important;
font-weight: 500;
text-decoration: none;
display: flex;
max-width: 100%;
}

a.primarybuttonother{
  background-color: #147885 !important;
  color: #fff;
  background-image: linear-gradient(rgba(0, 0, 0, 0) 67%, rgba(0, 86, 92, .52));
  border-radius: 6px;
  justify-content: center !important;
  align-items: center;
  height: auto;
  padding: 16px 24px !important;
  font-weight: 500;
  text-decoration: none;
  display: flex;
  width: 130px;
  }
  

a.secondarybutton{
  background-color: None !important;
  color: #043033;
  border-radius: 6px;
  justify-content: center !important;
  align-items: center;
  height: auto;
  padding: 16px 24px !important;
  font-weight: 500;
  text-decoration: none;
  display: flex;
  max-width: 100%;
  }


  a.secondarybutton:hover{
    background-color: #ffffff52 !important;
    color: #043033;
    border-radius: 6px;
    justify-content: center !important;
    align-items: center;
    height: auto;
    padding: 16px 24px !important;
    font-weight: 500;
    text-decoration: none;
    display: flex;
    max-width: 100%;
    }
.nav-link {
  padding: 0 20px 0 0 !important;
  color: #043033
}

a.nav-link {
  font-size: 1rem;
  font-weight: 500 !important;
  color: #043033 !important;

}

.nav-item {
  margin: 0 2rem;
}

.tab-button{
  width: 50%;
    border: 2px solid #424242;
    border-radius: 1rem;
    background-color: #424242;
    height: 3rem;
    color: #fff;

}

.tab-button.active {
  border: 2px solid #424242;
  border-radius: 1rem;
  background-color: #fff;
  color: #424242;
  height: 3rem;
}

.tab-container {

  display: flex;
  justify-content: space-around;
  border: 1px solid #424242;
  background-color: #424242;
  border-radius: 1rem;
  margin: 1rem;
}

button.secspecial{
  background-color: #00b8b1;
  color: white;
  margin: 5px;

}

button.special{
  background-color:#043033;
  color: white;
  margin: 5px;


}
@media screen and (max-width: 900px) {
  .navbar-collapse{
    background-color: #fff !important;
  } {

    background-color: white !important;
    color: #043033  !important;
  }

  a.nav-link {
    color: #043033  !important;
    padding: 1rem !important;
    margin: 1rem !important;
    text-align: center !important;
  }

  .hero-content{
    padding: 2rem !important;
    margin-top: 5rem;
  }

  .hero-heading{
    font-size: 2rem !important;
  }
}

.hero-content h1 {
  color: #043033 !important;
  font-size: 4.4rem;
  font-weight: 600 !important;
  line-height: 105% !important;
}

.hero-subheading {
  color: #043033 !important;
  font-size: 1.4rem !important;
  font-weight: 600 !important;

}



.bottom-right-img {
  bottom: 20px !important;
  left: 20px !important;
  height: 60px;
  width: 300px;
  display: flex;
  align-items: center !important;
}
.bottom-right{
  position: absolute;
  bottom: 10px !important;
  right: 20px !important;
  height: 60px;
  z-index: 1000;
}

.footer{
  z-index: 1000 !important;
  position: absolute;
  bottom: 10px !important;
  width: 100%;
  justify-content: flex-start;
  align-items: center !important;


}



.newhalfsize{
  background-color: transparent;
  display: flex;
  flex-direction: column; /* Add this line */
  justify-content: flex-end; /* Adjust to flex-end to push content to the bottom */
  align-items: center;
  min-height: 20vh;
}

.newhalfsizes{
  background-color: transparent;
  display: flex;
  flex-direction: column; /* Add this line */
  justify-content: center; /* Adjust to flex-end to push content to the bottom */
  align-items: center;
  min-height: 20vh;
}

/* Navbar styles */
.navbar {
  z-index: 1000;

}

.navbar-nav {
  display: flex;
          align-content: center;
          align-items: center;

}
.navbar-brand img {
  width: 200px;
  height: auto;
}

.navbar-nav .nav-link {
  color: #043033 !important;
  margin: 0 10px;
  font-size: 1rem;
  font-weight: 500 !important;
}

.navbar-nav .primarybutton,
.navbar-nav .secondarybutton {
  padding: 5px 15px;
  border-radius: 5px;
  color: #fff !important;
  text-decoration: none;
  transition: background 0.3s;
}

.navbar-nav .primarybutton {
  background-color: #28a745;
}

.navbar-nav .secondarybutton {
  background-color: #6c757d;
}

/* Hero Section */
.video-background {
  position: relative;
  width: 100%;
  height: 98vh;
  overflow: hidden;
}

.bg-video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.video-overlay {
  z-index: 1;
  background-image: linear-gradient(rgba(0, 0, 0, 0) 30%, #f6f5f3), radial-gradient(circle at 0%, rgba(141, 214, 108, .32), rgba(255, 255, 255, 0)), radial-gradient(circle at 90% -10%, #00b8b1, rgba(141, 214, 108, .73) 22%, rgba(233, 233, 235, 0) 89%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.hero-content {
  position: relative;
  z-index: 2;
  color: #fff;
  padding: 12rem;
  text-align: center;
}

.hero-heading {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.hero-subheading {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.primarybuttonother {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.primarybuttonother:hover {
  background-color: #0056b3;
}

.navbar-toggler {
  background-color: #043033;
  border: solid #147885;
}


.AppContainer{

  align-self: center;
  text-align: center;
  margin: 2rem auto;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 1px 1px 2px rgba(69, 28, 18, 0.02), 0px 5px 9px rgba(69, 28, 18, 0.03), 1px 12px 20px rgba(69, 28, 18, 0.05), 0px 20px 36px rgba(69, 28, 18, 0.06), 0px 32px 56px rgba(69, 28, 18, 0.08);

}


.AppContainer h1{
  font-size: 2rem;
  color: #043033;
}

.loadingbutton{
  justify-content: center;
  margin: auto;
  display: flex;
}

.file-drop-area {
  border: 2px dashed #043033;
  border-radius: 8px;
  padding: 40px;
  width: 60%;
  margin: 0 auto;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.file-drop-area:hover {
  background-color: #f1f9f0;
  color: #fff;
}

.file-drop-area p {
  margin: 0;
  font-size: 16px;
  color: #043033;
  font-weight: bold;
}



.file-input {

  opacity: 0; /* Make the input invisible but still interactive */
  cursor: pointer;

}

.file-input:focus {
  outline: none;
}

.error {
  color: red;
  font-size: 16px;
}


.mybutton{

  background-color: #337D76 !important;
  width: 400px;
  margin: 10px 20px;
  color: #fff;
  box-shadow: 0px 1px 2px rgba(69, 28, 18, 0.02), 0px 5px 9px rgba(69, 28, 18, 0.03), 0px 12px 20px rgba(69, 28, 18, 0.05), 0px 20px 36px rgba(69, 28, 18, 0.06), 0px 32px 56px rgba(69, 28, 18, 0.08);

}

.mybutton:hover{

  background-color: #424242 !important;
  width: 400px;
  margin: 10px 20px;
  color: #fff;
  box-shadow: 0px 1px 2px rgba(69, 28, 18, 0.02), 0px 5px 9px rgba(69, 28, 18, 0.03), 0px 12px 20px rgba(69, 28, 18, 0.05), 0px 20px 36px rgba(69, 28, 18, 0.06), 0px 32px 56px rgba(69, 28, 18, 0.08);

}
.options-container{
 display: flex;
 align-items: center;
 justify-content: center;
}